<template>
  <div v-if="reservation">
    <AdminReservationSummaryDetail
      :reservation="reservation"
      heading="Reservation"
    />
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mt-2 mb-0">
          Order
          <p class="mb-0 float-right">
            <span class="label" v-if="reservation.orderNumber"
              >Confirmation Number:</span
            >
            {{ reservation.orderNumber }}
          </p>
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <p class="mb-1">
              <span class="label">Reservation Status:</span>
              {{ reservation.status | formatEnum }}
            </p>
            <p class="mb-1">
              <span class="label">Discount Class:</span>
              {{ discount }}
            </p>
          </div>
          <div class="col-md-4 col-sm-12">
            <p class="mb-1">
              <span class="label">Transaction Date:</span>
              {{
                reservation.transactionDate
                  | formatDateTime("MM/DD/YYYY hh:mm A")
              }}
            </p>
            <p class="mb-1">
              <span class="label">Payment Type:</span>
              {{ reservation.paymentType | formatEnum }}
            </p>
          </div>
          <div class="col-md-4 col-sm-12">
            <p class="mb-1" v-for="resFee in reservationFees" :key="resFee.id">
              <span class="label">{{ resFee.name }}:</span>
              {{ (resFee.amount * resFee.quantity) | formatCurrency }}
            </p>
            <p class="mb-1" v-if="fee != null">
              <span class="label">{{ fee.name }}:</span>
              {{ fee.amount | formatCurrency }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminReservationSummaryDetail from "@/components/admin/AdminReservationSummaryDetail.vue";
import AdminPricingManagementService from "@/services/admin/AdminPricingManagementService.js";

export default {
  name: "AdminReservationSummary",
  components: {
    AdminReservationSummaryDetail
  },
  props: {
    reservation: Object,
    fee: { type: Object, default: null }
  },
  data() {
    return {
      discountClasses: []
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    discount() {
      if (this.reservation?.discounts?.length > 0) {
        let discountClassesApplicable = this.discountClasses.filter(x =>
          this.reservation?.discounts?.some(y => y.discountClassId == x.id)
        );
        if (discountClassesApplicable.length > 0) {
          const highestPriorityDiscount = discountClassesApplicable?.reduce(
            (prev, current) =>
              prev.priority > current.priority ? prev : current
          );
          return highestPriorityDiscount?.name ?? "Base";
        }
      }
      return "Base";
    },
    cost() {
      return !this.reservation.reservationFees
        ? ""
        : this.reservation.reservationFees
            .map(x => x.amount * x.quantity)
            .reduce((a, b) => a + b);
    },
    reservationFees() {
      if (this.reservation.reservationFees) {
        let newestFees = [];
        const resFees = this.reservation.reservationFees;
        resFees.sort((a, b) => {
          return b.id - a.id;
        });
        let parkUseFee = resFees.find(x => x.feeType === "ParkUse");
        if (parkUseFee !== undefined) {
          newestFees.push(parkUseFee);
        }
        let transactionFee = resFees.find(
          x =>
            x.feeType === "NewTransaction" || x.feeType === "ModifyTransaction"
        );
        if (transactionFee !== undefined) {
          newestFees.push(transactionFee);
        }
        let convenienceFee = resFees.find(x => x.feeType === "Convenience");
        if (convenienceFee !== undefined) {
          newestFees.push(convenienceFee);
        }
        let cancellationFee = resFees.find(x => x.feeType === "Cancellation");
        if (cancellationFee !== undefined) {
          newestFees.push(cancellationFee);
          const amountRefunded = {
            id: 0,
            name: "Amount Refunded",
            amount: parkUseFee.amount - cancellationFee.amount,
            quantity: 1
          };
          newestFees.push(amountRefunded);
        }
        let modificationFee = resFees.find(x => x.feeType === "Modification");
        if (modificationFee !== undefined && cancellationFee === undefined) {
          newestFees.push(modificationFee);
        }
        return newestFees;
      } else {
        return [];
      }
    }
  },
  methods: {
    async getDiscountClasses() {
      const service = new AdminPricingManagementService(this.tenantId);
      let discountClasses = await service.getDiscountClasses();
      this.discountClasses = discountClasses.map(x => {
        return { id: x.id, priority: x.priority, name: x.name };
      });
    }
  },
  created() {
    this.getDiscountClasses();
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
</style>
